<template>
  <nav class="nav swiper-control">
    <swiper
      :modules="[Controller, Pagination]"
      :controller="{ control: controlledSwiper }"
      slides-per-view="auto"
      :pagination="{ clickable: true }"
      class="options-container"
    >
      <swiper-slide class="option" v-for="(item, index) in links" :key="index">
        <router-link
          class="button-vesta-sw-cross"
          :to="item.to"
          @click="onClick(item.to)"
        >
          <img :src="item.src" alt="" />
          <span>{{ item.name }}</span>
        </router-link>
      </swiper-slide>
    </swiper>
  </nav>
</template>

<script>
import {
  PAGE_NAME_AURA,
  PAGE_NAME_VESTA_SW,
  PAGE_NAME_LARGUS,
} from "@/data/constants";
import { gtmVestaSWCross, gtmVestaSedan } from "@/utils/gtm";
import { Pagination, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      Pagination,
      Controller,
      links: [
        {
          name: "Largus универсал",
          to: { name: PAGE_NAME_LARGUS },
          src: require("@/assets/img/cars/largus/preview/mobile/preview-largus.webp"),
        },
        {
          name: "AURA",
          to: { name: PAGE_NAME_AURA },
          src: require("@/assets/img/cars/aura/preview/mobile/preview-aura.webp"),
        },
        {
          name: "VESTA SW CROSS",
          to: { name: PAGE_NAME_VESTA_SW },
          src: require("@/assets/img/cars/vesta-sw/preview/mobile/preview-sw.webp"),
        },
      ],
    };
  },
  methods: {
    onClick({ name }) {
      if (name === PAGE_NAME_AURA) {
        gtmVestaSedan();
      } else if (name === PAGE_NAME_VESTA_SW) {
        gtmVestaSWCross();
      }
    },
  },
};
</script>

<style lang="scss">
.nav {
  .swiper-wrapper {
    display: flex;
  }

  .option {
    width: 160px;
  }

  & a {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 160px;
      height: 100px;
    }

    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      color: #4f5862;
    }
  }
}
</style>

<template>
  <div v-show="!isInterior" class="settings">
    <div
      class="settings-item"
      :class="{
        'settings-item_type_full': gallery.colors && gallery.colors.length > 2,
      }"
    >
      <div class="settings-content">
        <div class="settings-content__title">Цвет</div>
        <div class="settings-content__desc">
          {{ gallery.colors[gallery.colorActive].title }}
        </div>
      </div>
      <div class="settings-list">
        <ul>
          <li v-for="(item, index) in gallery.colors" :key="index">
            <button
              :class="{ 'button-dark-border': item.hex === '#FFFFFF' }"
              @click="select(index)"
              :disabled="gallery.colorActive === index"
              :style="{ backgroundColor: item.hex }"
            ></button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsExtoriorPopover",
  props: {
    gallery: Object,
  },
  computed: {
    isInterior() {
      const { mode } = this.$route.query;
      return mode === "interior";
    },
  },
  methods: {
    select(index) {
      this.$emit("colorSelected", index);
    },
  },
};
</script>

<style lang="scss" scoped>
@include mobile {
  .settings {
    border-top: 1px solid rgba(79, 88, 98, 0.25);
    margin-top: uniWM(24);
    margin-bottom: uniWM(24);
    padding-top: uniWM(24);
    &-item {
      display: flex;
      justify-content: space-between;
      &:nth-child(2) {
        display: none;
      }
      &_type_full {
        flex-direction: column;
        align-items: flex-start;
        .settings-list {
          ul {
            margin: 0;
            flex-wrap: wrap;
            li {
              margin: 0;
              margin-top: uniWM(12);
              margin-right: uniWM(16);
              margin-bottom: uniWM(4);
            }
          }
        }
      }
    }
    &-content {
      padding-right: uniW(100);
      &__title {
        font-weight: 700;
        font-size: uniWM(16);
        line-height: uniWM(19);
        color: var(--colorDark);
      }
      &__desc {
        font-weight: 400;
        font-size: uniWM(16);
        line-height: 140%;
        color: var(--colorDark);
        margin-top: uniWM(4);
      }
    }
    &-list {
      ul {
        @include reset-list;
        display: flex;
        li + li {
          margin-left: uniWM(16);
        }
        li {
          button {
            @include reset-button;
            width: uniWM(46);
            height: uniWM(46);
            border-radius: uniWM(46);
            transition: var(--transitionTime) var(--cubicBezier);
            display: block;
            position: relative;
            &::before {
              position: absolute;
              top: uniWM(-1);
              left: uniWM(-1);
              width: calc(100% + uniWM(2));
              height: calc(100% + uniWM(2));
              box-shadow: inset 0 0 0 uniWM(6) var(--colorWhite);
              border-radius: uniWM(100);
              content: "";
            }
            &:disabled {
              cursor: default;
              &::before {
                box-shadow: inset 0 0 0 uniWM(2) var(--colorDark),
                  inset 0 0 0 uniWM(4) var(--colorWhite);
              }
            }
          }

          .button-dark-border {
            border: uniWM(6) solid #c3c4c4;

            &:disabled {
              border: none;
              &::before {
                box-shadow: inset 0 0 0 uniWM(2) var(--colorDark),
                  inset 0 0 0 uniWM(4) rgb(218, 219, 219);
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: $breakpoints-mobile), (orientation: landscape) {
  .settings {
    position: absolute;
    right: 0;
    width: 100%;
    min-width: uniW(360);
    bottom: uniW(53);
    text-align: left;
    display: grid;
    gap: uniW(8);
    &-item {
      background: rgba(255, 255, 255, 0.8);
      border: uniW(1) solid var(--colorWhite);
      box-sizing: border-box;
      box-shadow: 0px uniW(2) uniW(8) rgba(0, 0, 0, 0.25);
      border-radius: uniW(16);
      padding: uniW(17) uniW(24);
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_type_full {
        flex-direction: column;
        align-items: flex-start;
        .settings-list {
          ul {
            margin: 0;
            flex-wrap: wrap;
            li {
              margin: 0;
              margin-top: uniW(12);
              margin-right: uniW(16);
              margin-bottom: uniW(4);
            }
          }
        }
      }
    }
    &-content {
      &__title {
        font-weight: 700;
        font-size: uniW(16);
        line-height: uniW(19);
        color: var(--colorDark);
      }
      &__desc {
        font-weight: 400;
        font-size: uniW(16);
        line-height: 140%;
        color: var(--colorDark);
        margin-top: uniW(4);
      }
    }
    &-list {
      ul {
        @include reset-list;
        display: flex;
        margin: uniW(-10) 0;
        li + li {
          margin-left: uniW(16);
        }
        li {
          button {
            @include reset-button;
            width: uniW(46);
            height: uniW(46);
            border-radius: uniW(46);
            box-shadow: inset 0 0 0 uniW(8) var(--colorWhite);
            transition: var(--transitionTime) var(--cubicBezier);
            display: block;
            &:hover:not(:disabled) {
              box-shadow: inset 0 0 0 uniW(4) var(--colorWhite);
            }
            &:disabled {
              cursor: default;
              box-shadow: inset 0 0 0 uniW(1) var(--colorWhite);
            }
          }

          .button-dark-border {
            box-shadow: inset 0 0 0 uniW(8) #c3c4c4;

            &:hover:not(:disabled) {
              box-shadow: inset 0 0 0 uniW(4) #c3c4c4;
            }

            &:disabled {
              box-shadow: inset 0 0 0 uniW(2) #c3c4c4;
            }
          }
        }
      }
    }
  }
}
</style>

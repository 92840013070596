export const PANORAMA = {
  options: {
    name: "interior-largus",
    items: [
      {
        src: require("@/assets/img/cars/largus/interior/light-q75.webp"),
        srcMobile: require("@/assets/img/cars/largus/interior/mobile/light-q25.webp"),
        startLookAt: 270,
      },
      {
        src: require("@/assets/img/cars/largus/interior/dark-q75.webp"),
        srcMobile: require("@/assets/img/cars/largus/interior/mobile/dark-q25.webp"),
        startLookAt: 270,
      },
    ],
  },
  infospots: [
    {
      position: {
        x: 3250,
        y: -3415,
        z: 1030,
      },
      title: "Сиденье водителя с регулировками",
      desc: "Удобная настройка высоты и поясничной поддержки.",
      media: { picture: require("@/assets/img/cars/largus/media/6.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 2000,
        y: -4000,
        z: 0,
      },
      title: "Подлокотник водителя с боксом",
      desc: "Обеспечивает удобную посадку и место хранения.",
      media: { picture: require("@/assets/img/cars/largus/media/7.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 3800,
        y: -2500,
        z: 3500,
      },
      title: "Кнопки открывания стекол",
      desc: "Кнопки открывания стекол расположены на двери: для эргономики важна любая мелочь!",
      media: { picture: require("@/assets/img/cars/largus/media/8.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 1900,
        y: -2000,
        z: 4500,
      },
      title: "Обивка дверей",
      desc: "Обивки дверей: новое сочетание стиля и функциональности каждого элемента.",
      media: { picture: require("@/assets/img/cars/largus/media/9.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -1800,
        z: -500,
      },
      title:
        "8’’ мультимедийная система LADA ENJOY PRO c Яндекс.Авто, навигацией и качественным звуком",
      desc: "Дополнительный объем в новом стиле.",
      media: { picture: require("@/assets/img/cars/largus/media/10.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    // {
    //   position: {
    //     x: 4300,
    //     y: -700,
    //     z: -1700,
    //   },
    //   title: "Шумоизоляция",
    //   desc: "Шумоизоляция салона улучшена за счет дополнительных поглощающих элементов на кузове",
    //   media: { picture: require("@/assets/img/cars/largus/media/11.webp") },
    //   gtmReachGoal: "largus-cross_interior_pin-doors",
    // },
    {
      position: {
        x: 4600,
        y: 1300,
        z: 0,
      },
      title: "Датчик дождя и света",
      desc: "Автоматически включают фары и стеклоочистители.",
      media: { picture: require("@/assets/img/cars/largus/media/12.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: 200,
        z: 2300,
      },
      title: "Обогрев лобового стекла",
      desc: "Эффективное средство против запотевания и обмерзания.",
      media: { picture: require("@/assets/img/cars/largus/media/13.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 3700,
        y: -1200,
        z: 2900,
      },
      title: "Круиз-контроль",
      desc: "Позволяет поддерживать оптимальный скоростной режим.",
      media: { picture: require("@/assets/img/cars/largus/media/14.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -3800,
        z: -500,
      },
      title: "Подогрев передних сидений",
      desc: "Трехуровневая регулировка температуры.",
      media: { picture: require("@/assets/img/cars/largus/media/15.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -2800,
        z: -500,
      },
      title: "Климатическая система с кондиционером",
      desc: "Эффективная и мощная. Благодаря воздуховодам ко 2 и 3 рядам сидений комфортная температура распределяется по всему салону автомобиля.",
      media: { picture: require("@/assets/img/cars/largus/media/16.webp") },
      gtmReachGoal: "largus-cross_interior_pin-doors",
    },
  ],
};

export const GALLERY = {
  folder: "largus/exterior",
  colorActive: 0,
  colors: [
    {
      title: 'БЕЛЫЙ "ЛЕДНИКОВЫЙ" (221)',
      hex: "#FFFFFF",
      folder: "white",
      fileName: {
        light: "LARGUSwhite_day00",
      },
    },
    {
      title: 'СЕРЕБРИСТЫЙ "ПЛАТИНА" (691)',
      hex: "#C3C4C4",
      folder: "platinum",
      fileName: {
        light: "LARGUSplatinum_day00",
      },
    },
    {
      title: 'ЧЕРНЫЙ "ПАНТЕРА" (672)',
      hex: "#161313",
      folder: "black",
      fileName: {
        light: "LARGUSblack_day00",
      },
    },
    {
      title: 'СЕРЕБРИСТО-ТЕМНО-СЕРЫЙ "БОРНЕО" (633)',
      hex: "#444E4E",
      folder: "borneo",
      fileName: {
        light: "LARGUSborneo_day00",
      },
    },
    {
      title: 'СИНИЙ "КАПИТАН" (493)',
      hex: "#154889",
      folder: "blue",
      fileName: {
        light: "LARGUSblue_day00",
      },
    },
  ],
  breakpoints: [
    {
      slideIndex: 0, // 10
      items: [
        {
          offset: {
            top: "7vw",
            left: "1vw",
          },
          offsetMobile: {
            top: "3",
            left: "1.5",
          },
          title: "15’’ Легкосплавные диски",
          desc: "Стильные и прочные, специально для Largus.",
          media: {
            picture: require("@/assets/img/cars/largus/media/1.webp"),
          },
          gtmReachGoal: "largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "4.5vw",
            left: "12vw",
          },
          offsetMobile: {
            top: "1",
            left: "11",
          },
          title: "Архитектура бампера",
          desc: "В архитектуре бампера – фирменная «Икс-графика» LADA. Узнаваемая, и по-новому интересная. Фары стали крупнее: у них раздельные секции ближнего и дальнего света, а также дневные ходовые огни.",
          media: {
            picture: require("@/assets/img/cars/largus/media/2.webp"),
          },
          gtmReachGoal: "largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-8vw",
            left: "-11vw",
          },
          offsetMobile: {
            top: "-12",
            left: "-10",
          },
          title: "Рейлинги черного цвета",
          desc: "Дополняют облик автомобиля и выдерживают нагрузку до 50 кг.",
          media: {
            picture: require("@/assets/img/cars/largus/media/3.webp"),
          },
          gtmReachGoal: "largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-2vw",
            left: "-7vw",
          },
          offsetMobile: {
            top: "-5",
            left: "-5",
          },
          title: "Наружные зеркала",
          desc: "Наружные зеркала: улучшенная аэродинамика подчеркивает стремительность автомобиля",
          media: {
            picture: require("@/assets/img/cars/largus/media/5.webp"),
          },
          gtmReachGoal: "largus-cross-exterior-pin-frontbumper",
        },
      ],
    },
    {
      slideIndex: 50, // 70
      items: [],
    },
    {
      slideIndex: 170, // 190
      items: [
        {
          offset: {
            top: "-6vw",
            left: "11vw",
          },
          offsetMobile: {
            top: "-7",
            left: "10",
          },
          title: "Вместительный багажник",
          desc: "При сложенном третьем ряде сидений Largus превращается в пятиместный универсал с внушительным багажником объемом 560 литров. Максимальный объем при сложенных втором и третьем рядах сидений достигает 2350 литров!",
          media: {
            picture: require("@/assets/img/cars/largus/media/4.webp"),
          },
          gtmReachGoal: "largus-cross-exterior-pin-frontbumper",
        },
      ],
    },
  ],
};
